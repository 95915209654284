.bottom-center-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  z-index: 999;
}

.bottom-center-button {
  margin: 20px;
}

.css-1byr0tz .MuiTablePagination-toolbar .css-w7tncj-MuiTablePagination-selectLabel, 
.css-1byr0tz .MuiTablePagination-toolbar .css-jr9x4l-MuiTablePagination-displayedRows{
margin: 0;
}
