#webpack-dev-server-client-overlay {
  display: none !important;
}

#webpack-dev-server-client-overlay-div {
  display: none !important;
}

.rpv-core__inner-page {
  background-color: 'transparent';
}

.rpv-toolbar__center {
  flex-grow: 0.89;
}

.rpv-toolbar__right {
  display: none;
}

.rpv-core__icon {
  color: white;
}

.rpv-core__textbox {
  height: 25px;
  width: 30px;
  margin-top: 4px;
  font-size: 14px;
}
