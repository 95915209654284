.table td {
    border-top: none;
}

.table th {
    border-top: none;
}

.table{
    border-collapse: separate;
}

.edit {
    border: 1px solid #3f6af5;
    background: none;
    border-radius: 5px;
    padding: 5px 10px;
    color: #3f6af5;
    font-weight: 700;
}

.form-control{
    display: flex;
}

.main {
    width: 50%;
    margin: 50px auto;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.person{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.title{
    font-weight: 700;
    font-size: 25px;
}

.create {
    border: 1px solid #3f6af5;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #3f6af5;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plus{
    margin-right: 5px;
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Arka planı hafif karanlık yapar */
    z-index: 9998; /* Popup'ın arkasında kalır */
}

.popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999; /* Popup'ın diğer içeriklerin üstünde olmasını sağlar */
}


.popup-title{
    font-weight: 700;
    font-size: larger;
    color: black;
}

.form-check-input:checked {
    background-color: #3f6af5;
    border-color: #3f6af5;
}

.select-box{
    display: flex;
    justify-content: space-between;
}

.buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.put{
    margin-right: 10px;
    border: 1px solid #ff9d97;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #3f6af5;
    font-weight: 700;
}

.cancel{
    margin-right: 10px;
    color: #3f6af5;
    font-weight: 700;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    font-weight: 700;
}

/* Select alanlarını sarmalayan div'in stilini belirleme */
.select-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

/* Select alanı stilini belirleme */
.select-wrapper select {
    /* Burada istediğiniz diğer stilleri belirtebilirsiniz */
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    width: 100%;
}

/* Font Awesome ikonları için gerekli stil */
.fa-chevron-down {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #495057; /* Ikon rengi */
}

.width{
    width: 100%;
}

.close{
    text-align: end;
}

hr {
    display: block;
    /* height: 1px; */
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.dialogs .MuiPaper-root{
    max-width: 800px !important;
}

.table-container{
    width: max-content !important;
}