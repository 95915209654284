.editable-data-table-container {
  margin: 20px;
}

.editable-data-table input,
.editable-data-table select {
  width: 100%;
}

.editable-data-table {
  width: 100%;
}
